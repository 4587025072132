@use "sass:math";

$intl-tel-input-sprite-path: "/static/img/_common/flags.png" !default;
$intl-tel-input-sprite-2x-path: "/static/img/_common/flags@2x.png" !default;

$emSize: 16;

$white: #fff;
$solitude: #dae3e7;
$light: #e3f4fe;
$lightGray: #e6e8eb;
$lightGray2: #f0f1f2;
$zircon: #f3fafe;
$gray: #f4f4f4;
$brand: #24a06d;
$red: #ed6347;
$realRed: red;
$yellowOrange: #ebc627;
$secondary: #0d2b51;
$grey: #a2aab3;
$darkGray: #6e7071;
$default: #404042;
$almostBlack: #19191b;
$primary: "#58b3ca";
$sham: $brand;

$font-c: Campton-book, sans-serif;
$font-cm: Campton-medium, sans-serif;
$font-cl: Campton-light, sans-serif;
$font-csb: Campton-semibold, sans-serif;
$font-cb: Campton-bold, sans-serif;
$font-mr: Monosten-regular, sans-serif;
$font-mb: Monosten-bold, sans-serif;
$font-ml: Monosten-light, sans-serif;
$font-i: "Inter", sans-serif;
$font-regular: $font-c, sans-serif;
$font-medium: $font-cm, sans-serif;
$font-bold: $font-cb, sans-serif;
$font-light: $font-cl, sans-serif;
$font-icomoon: "icomoon", sans-serif;

//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xxs: 23.75rem !default; //380px

$screen-xs: 30rem !default; //480px

//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 47.9375rem !default; //767px
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 62rem !default; //992px
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

$screen-lgmd: 64rem !default; //1024px

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 75rem !default; //1200px
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

$screen-lgxl: 90rem !default; //1440
$screen-xl: 120rem !default; //1920

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 0.1) !default; // 767px
$screen-sm-max: ($screen-md-min - 0.1) !default; // 992px
$screen-md-max: ($screen-lgmd - 0.1) !default; // 1024px
$screen-lgmd-max: ($screen-lg - 0.1) !default; // 1199px
$screen-lg-max: ($screen-xl - 0.1) !default; // 1919px

//$xs: 34rem;
//$sm: 48rem;
//$md: 62rem;
//$lg: 75rem;
//$lgxl: 90rem;
//$xl: 120rem;
$breakpoints: (xl $screen-lgxl) (lg $screen-md-max) (md $screen-sm-max) (sm $screen-xs-max)
  (xs $screen-xs-min);

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color !important;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color !important;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color !important;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color !important;
  }
}

$v4_default: #19191b;
$v4_shark: #222324;
$v4_montana: #393a3a;
$v4_smoke: #f9f9f9;
$v4_wildSand: #f5f5f5;
$v4_iron: #cfd2d3;
$v4_butterfly: #6b4ca4;
$v4_nevada: #6e7071;
$v4_algea: #97e2c3;
$v4_salomie: #ffe783;
$v4_genoa: #117e5b;
$v4_chill: #0a7594;
$v4_prelude: #c1b2df;
$v4_deluge: #7c60b0;
$v4_ocean: #35a678;
$v4_eastSide: #9d88c4;

$v5_default: #19191b;
$v5_rangoon: #19191b;
$v5_nobel: #b7b7b7;
$v5_porcelain: #f3f4f5;
$v5_manatee: #9a9a9c;
$v5_athens: #e6e8eb;
$v5_chinook: #a3e1c2;
$v5_gallery: #eceaea;
$v5_chalice: #a8aaab;
$v5_shakespeare: #58b3ca;
$v5_alto: #dbdbdb;
$v5_algae: #97e2c3;

$v5_pc: #58b3ca;
$v5_uc: #fac932;
$v5_ortho: #baa3bf;
$v5_insurance: #baa3bf;
$v5_preventive: #24a06d;
$v5_specialists: #baa3bf;
$v5_golden: #ebc627;
$v5_cinnamon: #e15c18;
$yellow: $v5_golden;
$deepBlue: #2020ff;

//FINAL V5 COLORS START
$gray50: #f9f9f9;
$gray100: #e6e8eb;
$gray200: #a5a5a5;
$gray400: #6e7071;
$gray600: #404042;
$gray800: #191919;

$lighterBlue: #9eeaef;
$lightBlue: #80d3df;
$blue: #58b3ca;
$darkBlue: #408fad;
$darkerBlue: #1c4f75;

$lighterGreen: #b8f2c2;
$lightGreen: #6bd9a1;
$green: #24a06d;
$darkGreen: #158562;
$darkerGreen: #0b5c53;

$lighterPurple: #f6e4f5;
$lightPurple: #d6c0d8;
$purple: #baa3bf;
$darkPurple: #9877a4;
$darkerPurple: #56336e;

$lighterYellow: #f3ecbb;
$lightYellow: #f3d95a;
$yellow: #ebc627;
$darkYellow: #caa51c;

$darkerYellow: #88690c;
$lighterRed: #fcd1a1;
$lightRed: #ec8e4e;
$red: #e15c18;
$darkRed: #c14111;
$darkerRed: #821907;

$lighterMint: #e0fce5;
$lightMint: #bceccf;
$mint: #a3e1c2;
$darkMint: #77c1a3;
$darkerMint: #338272;
//FINAL V5 COLORS END

$mobileWidth: 23.4375rem;

$v5_containerWidth: 118.75rem; //rem
$v5_containerWidthMobile: 23.4375rem; //23.4375rem
$v5_containerWidthMobileMini: 22.5rem; //23.4375rem
$spacerForMobile: 7.3rem; //rem
$headerHeight: 80px;
$headerHeightXs: 57px;
$coronavirusContainerWidth: 82.125rem;
$coronavirusTestingMobileHeaderHeight: 76px; // searchbox
$coronavirusTestingMobileMapHeight: 280px; // map height
$coronavirusTestingMobileHeight: $coronavirusTestingMobileHeaderHeight +
  $coronavirusTestingMobileMapHeight; // map height

$covidLandingMax: 1360px;

@mixin normal {
  font-family: $font-c;
}

@mixin med {
  font-family: $font-medium;
}

@mixin bold {
  font-family: $font-bold;
}

@mixin camp {
  font-family: $font-c;
}

@mixin campL {
  font-family: $font-cl;
}

@mixin campM {
  font-family: $font-cm;
}

@mixin campSB {
  font-family: $font-csb;
}

@mixin campB {
  font-family: $font-cb;
}

@mixin csb {
  font-family: $font-csb;
}

@mixin semibold {
  font-family: $font-csb;
}

@mixin cb {
  font-family: $font-cb;
}

@mixin mon {
  font-family: $font-mr;
}

@mixin monb {
  font-family: $font-mb;
}

@mixin monb-imp {
  font-family: $font-mb !important;
}

@mixin monl {
  font-family: $font-ml;
}

@mixin font-il {
  font-family: $font-i;
  font-weight: 300;
}
@mixin font-ir {
  font-family: $font-i;
  font-weight: 400;
}
@mixin font-im {
  font-family: $font-i;
  font-weight: 500;
}
@mixin font-isb {
  font-family: $font-i;
  font-weight: 600;
}
@mixin font-ib {
  font-family: $font-i;
  font-weight: 700;
}

@mixin interL {
  font-family: $font-i;
  font-weight: 300;
}
@mixin inter {
  font-family: $font-i;
  font-weight: 400;
}
@mixin interM {
  font-family: $font-i;
  font-weight: 500;
}
@mixin interSB {
  font-family: $font-i;
  font-weight: 600;
}
@mixin interB {
  font-family: $font-i;
  font-weight: 700;
}

@mixin btn($color: #fff, $bg: $v5_default, $darkness: 10%) {
  color: $color;
  background-color: $bg;
  //line-height: 60px;
  border-radius: 40px;
  padding: 1.3em 1.5em;
  display: inline-block;
  @include font-ib;
  border: 0;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: $color;
    background-color: darken($bg, $darkness);
  }
  @include contrast {
    border: 1px solid transparent;
  }
}

@mixin af($w: 280px) {
  grid-template-columns: repeat(auto-fit, minmax($w, 1fr));
}

@mixin mobileContainer {
  max-width: $screen-xxs;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@mixin ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@function toRem($px) {
  @return math.div($px, 16) + rem;
}

@mixin xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin paddingVertical($val, $val2: $val) {
  padding-top: #{$val}rem;
  padding-bottom: #{$val2}rem;
}

@mixin paddingHorizontal($val, $val2: $val) {
  padding-left: #{$val}rem;
  padding-right: #{$val2}rem;
}

@mixin marginVertical($val, $val2: $val) {
  margin-top: #{$val}rem;
  margin-bottom: #{$val2}rem;
}

@mixin marginHorizontal($val, $val2: $val) {
  margin-left: #{$val}rem;
  margin-right: #{$val2}rem;
}

@mixin fontSize($val, $val2: null) {
  @if ($val2) {
    font-size: #{toRem($val)};
    line-height: #{toRem($val2)};
  } @else {
    font-size: #{toRem($val)};
  }
}

@mixin contrast {
  @media screen and (-ms-high-contrast: active), screen and (forced-colors: active) {
    @content;
  }
}
