@import "../../_scss/vars";

.mobImg {
  display: none;
  margin-bottom: 1rem;
}

.imgWithBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.label {
  @include font-ir;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--color, #0b5c53);
  background: var(--bgColor, #bceccf);
  border-radius: 12px;
  line-height: 2;
  padding: 0 0.5rem;
}

.h5 {
  @include font-im;
  font-size: 13px;
  letter-spacing: 2px;
  color: var(--color, #6e7071);
  font-weight: normal;
}

.btn-bold {
  @include bold;
}

.btn-disabled {
  opacity: 0.6;
  pointer-events: none;
}
.btn {
  @include btn();
  color: var(--color, #fff);
  background-color: var(--bg, #000);
  font-size: var(--fontSize, medium);
  //line-height: var(--lineHeight, 50px);
  white-space: nowrap;
  &:hover {
    transition: all 0.2s;
    color: var(--hover, #fff);
    background-color: var(--hoverBg, #000);
  }
}

@media (max-width: $screen-md) {
  .mobImg {
    display: block;
  }
}
