@import "../../_scss/vars";
$leftTopHeight: 68px;
.map {
  display: flex;
  flex-direction: row;
  height: 100vh;

  padding-top: $headerHeight;

  :global {
    .gm-bundled-control {
      bottom: 7.25rem !important;
      right: 3.75rem !important;
    }

    .gmnoprint > div {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .gm-control-active {
      width: 3.125rem !important;
      height: 3.125rem !important;
      border-radius: 50% !important;
      background-color: $v5_default !important;
      color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      font-family: $font-icomoon !important;
      font-size: 1rem;

      ~ div:first-of-type {
        display: none !important;
      }

      &:hover {
        background-color: #fff !important;
        color: $v5_default;
      }

      img {
        display: none !important;
      }

      &:before {
        content: "\e999";
      }

      &:last-child {
        top: 0.4375rem !important;

        &:before {
          content: "\e998";
        }
      }
    }

    .gm-style-iw {
      //background-color: transparent;
      //box-shadow: none;
      max-width: none !important;
      padding: 0;
      border-radius: 1.25rem;
    }

    .gm-style-iw-a {
      margin-top: -1rem !important;
    }

    .gm-style-iw-d {
      overflow: hidden !important;
      max-width: none !important;
      max-height: none !important;
    }

    .gm-style-iw.gm-style-iw-c {
      max-height: none !important;
      button.gm-ui-hover-effect {
        display: none !important;
      }
    }

    .gm-style img[src="/static/img/v5/marker2.svg"] {
    }

    .gm-style img[src="/static/img/v5/markerActive2.svg"] {
      filter: var(--filterActive);
    }

    .gm-style img[src="/static/img/_common/m2.png"] {
      //filter: hue-rotate(163deg);
    }
    .gm-style-iw-t::after {
      display: none;
    }
    .gm-style .gm-style-iw-c {
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15) !important;
      padding: 0 !important;
    }

    .cluster > div {
      font-family: $font-i !important;
      font-weight: 700 !important;
      color: #fff !important;
    }
  }
}

.left {
  display: flex;
  position: relative;
  flex: 1;
  z-index: 3;
  flex-direction: column;
}

.leftTopWrapper {
  width: 100%;
  z-index: 2;

  :global(.scrollmagic-pin-spacer) {
    > div {
      z-index: 2;
    }
  }
}

.leftMain {
  position: relative;
  overflow-y: auto;
}

.right {
  position: relative;
  flex: 1;
}

.rightInner {
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
  }
}

.locs {
  display: grid;
}

.loc {
  &:hover {
    color: unset;
  }
}

.locEmpty {
  padding-top: 1rem;
}

.locEmptyMobileWrapper {
  text-align: center;
  padding-bottom: 8px;
  display: none;
}

.title {
  color: $default;
  font-size: 18px;
  font-family: $font-bold;
  display: block;
}

.address {
  margin-top: 0.3rem;
  color: $v4_nevada;
  font-family: $font-medium;
  font-size: 18px;
}

.modalities {
  color: $v4_nevada;
  margin-top: 0.5rem;
  font-size: 15px;
  display: block;
}

.m {
  margin-top: 0.2rem;
}
.m0 {
}
.m1 {
  margin-right: 0.3rem;
}
.m2 {
  margin-left: 0.2rem;
  @include med;
}

.locDetails {
  background-color: $v4_smoke;
  width: 330px;
  border-radius: 11px;
  padding: 1.4rem 1.2rem;
  position: relative;
  z-index: 1;
}

.details {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  margin-top: 0.7rem;
  align-items: center;
  grid-gap: 0.2rem;
}

.ratingWrap {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: flex-end;
  grid-gap: 0.2rem;
}

.star {
  font-size: 12px;
  position: relative;
  top: -2px;
}

.rating {
  font-size: 14px;
  display: inline-block;
}

.dot {
  font-size: 16px;
  display: inline-block;
}

.dist {
  font-size: 14px;
  display: inline-block;
}

.isOpen {
  font-size: 14px;
  display: inline-block;
  color: $brand;
  &.no {
    color: $red;
  }
}

.next {
  margin-top: 0.3rem;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
  font-family: $font-medium;
  font-size: 14px;
}

.select {
  margin-top: 1.2rem;
  display: block;
}

.select__btn {
  background-color: $v5_default;
  color: #fff;
  border-radius: 50px;
  padding: 0.9rem 1rem;
  display: inline-block;
  &:hover {
    background-color: lighten($v5_default, 10%);
    color: #fff;
  }
}

.info {
  width: 240px;
  padding: 1.4rem 1.2rem;
  background-size: cover;
  background-position: center;
  height: auto !important;
  background-color: #fff;
  border-radius: 1.25rem;
  overflow: hidden;
  .btn {
    padding: 0 0.5rem;
    line-height: 1.75rem;
    font-size: 12px;
  }
}

.select2 {
  margin-top: 0.5rem;
}

.select__btn2 {
  background-color: $v5_athens;
  color: $v5_default;
  &:hover {
    color: $v5_default;
    background-color: darken($v5_athens, 10%);
  }
}

@media (max-width: $screen-lgmd) {
  .map {
    flex-direction: column;
    padding-top: $headerHeight;
    height: calc(100vh - 5rem); // ios-safari has a bug (100vh is calculated wrong)

    :global {
      .scrollmagic-pin-spacer {
        position: static !important;
        height: auto !important;
        min-height: auto !important;
        padding: 0 !important;
        > div {
          position: static !important;
          top: 0 !important;
          width: auto !important;
        }
      }
    }
  }

  .left {
    padding: 0 !important;
    flex: none;
  }
  .leftTopWrapper {
    position: static;
  }
  .locEmptyMobileWrapper {
    display: block;
  }
}

@media (max-width: $screen-sm) {
  .map {
    padding-top: $headerHeightXs;
  }
}
