@import "../../_scss/vars";

.form {
  :global {
    .intl-tel-input {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 0.5rem;
      background-color: #fff;
      border-radius: 10px;
      .flag-container {
        position: static;
        display: grid;
        grid-auto-flow: column;
        //padding-left: 8px;
        .selected-flag {
          display: inline-grid;
          grid-auto-flow: column;
          align-items: center;
          width: auto;
          padding: 0 6px;
          border-radius: 10px;
          .iti-flag {
            margin-left: 10px !important;
            transform: scale(1.5);
            position: static !important;
            box-shadow: none;
          }
          .arrow {
            position: static;
            margin-left: 13px;
            margin-top: 3px;
            font-size: 7px;
            &:before {
              font-family: $font-icomoon;
              content: "\e92c";
            }
            &:after {
              display: none;
            }
          }
        }
      }

      .phone {
        padding-left: 10px !important;
        border: 0;
        line-height: 56px;
        background-color: transparent;
        font-size: 18px;
        font-family: $font-cm;
      }

      .country-list {
        top: 100%;
        .country {
          padding: 8px 10px;
        }

        @include contrast {
          overflow: scroll;

          &::-webkit-scrollbar-thumb {
            border: 1px solid transparent;
          }
        }
      }
    }
  }

  display: inline-grid;
  grid-template-columns: 330px 160px;
  grid-gap: 10px;
  margin-top: 0.5rem;
  .btn {
    width: 160px;
    border-radius: 60px;
    font-size: 15px;
    padding: 0;
    background: $darkGreen;
    color: $white;
  }

  .mobile {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 1rem;
  }

  .downlaodBtn {
    background: #24a06d;
    border-radius: 60px;
    font-size: 15px;
    line-height: 58px;
    color: #fff;
    padding: 0 1.4rem;
    @include bold;
    &:hover {
      background-color: darken(#e0fce5, 10%);
      color: #e0fce5;
    }
  }
}
